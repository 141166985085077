import {messages as portugueseMessages} from './BR';
import {messages as EspanholMessages} from './ES';
import {messages as EUAMessages} from './US';

const messages = {
  ...portugueseMessages,
  ...EspanholMessages,
  ...EUAMessages
}

export {messages}