import "./process.css"
import 'react-phone-number-input/style.css'
import React,{useEffect, useState} from "react";
import { useParams,useNavigate } from "react-router-dom";
import Header from "../../Components/Header";
import ClipLoader from "react-spinners/ClipLoader";
import Swal from "sweetalert2";
import p1 from '../../Components/asserts/p1.webp'
import vd from '../../Components/asserts/cvd.mp4'
import Footer from '../../Components/Forteer'
import Logo from '../../Components/asserts/logo.png';
import hacker from '../../Components/asserts/hakcer.gif';

import {i18n} from '../../Translate/i18n';
import axios from "axios";

 function Processando() {
  const {telefone}= useParams();
 

  const [status,setStatus] = useState('')
  const navigate = useNavigate()
  const [foto,setFoto] = useState("")
  const [loading,setLoading] = useState(false)

  const {phone} = telefone.split('+');

 
  useEffect(()=>{
    async function foto(){
      try {

        const response = await axios.get(`https://api.z-api.io/instances/3CA35A065383C0C85FE462C45B396465/token/277578B876E7F3F82C4CB01C/profile-picture?phone=${telefone}`,{
          headers: {
            'Client-Token' : 'F6416f34c98e9411c8e5d77087d5d1b2cS'
        }})

        setStatus(response.statusText)
        
      
      setFoto(response.data.link)

      setLoading(true)

        
      } catch (error) {
        console.error(error);
        
      }
    }

    
    foto()

    var i = 0;
  function move() {
    if (i == 0) {
      i = 1;
      var elem = document.getElementById("myBar");
      var progress = document.getElementById("progress")
      var width = 1;
      var id = setInterval(frame, 100);
      function frame() {
        if (width >= 100) {
          clearInterval(id);
          i = 0;
        } else {
          width++;
          elem.style.width = width + "%";
          progress.innerHTML = width + "%";

          
           if(elem.style.width =="100%"){
            Swal.fire({
              title: `${i18n.t('titles.seq3')}`,
              icon: "success",
              confirmButtonText: `${i18n.t('buttons.btn')}`,
              text: `${i18n.t('titles.seq4')}`,
             
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                elem.style.visibility = "hidden";
             navigate(`/provas/${telefone}`)
              } else if (result.isDenied) {
                Swal.fire("Changes are not saved", "", "info");
              }
            });
          }
        }
      }
    }
  }
  move()
  
  },[])


  
 return (
  <div className="cont">
    <Header/>
   <div className="boxLoading">
  
  <div className="pt">
  <div className="blss"></div>
  <div><span>{i18n.t('titles.titu')}</span></div>
  </div>
    <img src={hacker} width={300} height={200} />

    <div className="bxmages">
      <div>{loading ? <img src={foto} width={63}/> : false} </div>
      <div><span>{telefone}</span></div>
      </div>
      
     

    <div className="boxlocalisando">
     <div className="boxprogresso">
     <div id="progress"></div>
     <div ><span>Localizando...</span></div> 
   
     </div>
      <div id="myProgress">
  <div id="myBar"></div>
</div>
    </div>

    <div className="separador"></div>
    <div id="acessando" className="boxList">
      <span> <ClipLoader
      color="green"
        size={15}
        aria-label="Loading Spinner"
        data-testid="loader"
      />Acessando dispositivo...</span>
      <span> <ClipLoader
      color="green"
        size={15}
        aria-label="Loading Spinner"
        data-testid="loader"
      />{i18n.t('boxpainel.acesso')}</span>
      <span><ClipLoader
      color="green"
        size={15}
        aria-label="Loading Spinner"
        data-testid="loader"
      /> {i18n.t('boxpainel.info')}</span>
      <span><ClipLoader
      color="green"
        size={15}
        aria-label="Loading Spinner"
        data-testid="loader"
      /> {i18n.t('boxpainel.loc')}</span>
    </div>
    
    <div className="separador"></div>

  
   </div>
  

  </div>
  
  );
 }


export default Processando;