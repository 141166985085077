const messages = {

    US:{
        translations:{
            titles:{
                app:'cell phone locator',
                menBox:'Congratulations',
                titulo:'Access Blocked!',
                tituloseq:'How do I access the cell phone of the person being located?',
                seq1:'1 - It is necessary to pay a fee for the service and application that will be made available.',
                seq2:'2 - After payment, the application will be sent directly to your email.',
                seq3:'3 - Follow the same procedure done on the website.',
                seq4:'4 - Enter the persons number and wait for the system to complete access to the cell phone.',
                tax1:'To use ViewerSpy and connect on your cell phone',
                tax2:', We require a small symbolic contribution of R$19.87.',
               
                
                tit:'loading...'

            },
            messages:{
                inputtext:'Example: (00) 00000-0000',
                itWorks:'Enter number to locate',
                itMen:'Cell phone successfully located!',
                boxmensagem:'To access the cell phone',
                contmensagem:', Click "CONTINUE" to understand how ViewerSpy works.'
            },
            buttons:{
                save:'Track cell phone',
                btn:'CONTINUE',
                taxa:'PAY FEE',
                btnclicou:'Press here'
            },
            boxpainel:{
                acesso:'Connecting to the panel',
                info:'Cell phone information',
                loc:'Locating cell phone',

            }


        }
    }


    

}

export {messages}