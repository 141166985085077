import './App.css';
import 'react-phone-number-input/style.css'
import React,{useState,useEffect} from 'react';
import Header from "../../Components/Header";
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import useGeoLocation from "react-ipgeolocation";
import {i18n} from '../../Translate/i18n';
import WhatsappIcon from '../../Components/asserts/Whatsapp-Icon.png'
import iphone from '../../Components/asserts/iphone.svg'
import macewindows from '../../Components/asserts/mac-or-windows.svg'
import windowsphone from '../../Components/asserts/windows-phone.svg'
import android from '../../Components/asserts/android.svg'
import p1 from '../../Components/asserts/p1.webp'
import p3 from '../../Components/asserts/p3.webp'
import p6 from '../../Components/asserts/p6.webp'
import Swal from "sweetalert2";
import axios from "axios";
import Modal from 'react-modal';
import LoadingBar from 'react-top-loading-bar'
import {FaCheckCircle } from "react-icons/fa";
import Footer from '../../Components/Forteer'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
Modal.setAppElement('#root');

function Home() {
    const [telefone, setTelefone] = useState("");
    const location = useGeoLocation();
    const I18N_STORAGE_KEY = 'i18nextLng'
    let subtitle;
    const [modalIsOpen, setIsOpen] = useState(false);
    const [progress, setProgress] = useState(0)
    const [ip, setIp] = useState(0)
    const [st, setst] = useState('bl')
    const [stm, setstm] = useState('blk')
    const [bv, setbv] = useState('bl')
    const [bm, setbm] = useState('bl')
    const [bz, setbz] = useState('bl')
    const [bd, setbd] = useState('bl')
    const [ba, setba] = useState('bl')
    const [bs, setbs] = useState('bl')
    const [abtn, setabtn] = useState('abtn')
    const [start, setStart] = useState(false)
    const data = new Date();
    const novadata = data.toLocaleDateString();


    async function openModal() {
      setIsOpen(true);
      setStart(true)
   
      const response = await axios.get('https://ipapi.co/json/')

  setIp(response.data.ip)
    
    }
   
    function closeModal() {
      setIsOpen(false);
    }
   useEffect(()=>{
    function selectLanguagem(){
      localStorage.setItem(
        I18N_STORAGE_KEY,
        location.country
      )

     
     
    }

    selectLanguagem()


   
    
   },[])

   

   if(start == true){
    
    startbar()
    
  }else{
    
    clearTimeout(startbar)
   
  }

  function startbar(){
  
 setTimeout(()=>{
      
   
    setProgress(progress+1)
    
      
if(progress == 11){
  setst('bt')
  setstm('bt')

}else
if(progress == 21){
  setbv('blk')
 
}
else
if(progress == 31){

  setbm('bt')
  setbv('bc')
 
}
else
if(progress == 41){
  setbz('blk')
 
}
else
if(progress == 51){
  setbd('bt')
  setbz('bc')
 
}
else
if(progress == 61){
  setba('blk')

 
}
else
if(progress == 100){
  setbs('bt')
  setba('bc')
  setabtn('btna')
  setProgress(100)
  setStart(false)
 


 
}

 
 },1000)

  }
   

 
   

  return (
    <div className='containerBox'>
    
   

    <Header/>

      <div className='boxinfo'>
        
        <img src={WhatsappIcon} width={300} />
        <h1>{i18n.t('titles.app')} <br></br>
          <b> {i18n.t('titles.appi')}</b>
        </h1>
     
          <div className='separator'></div>
          <PhoneInput
              defaultCountry={location.country}
              value={telefone}
              initialValueFormat='international'
              onChange={setTelefone}
              placeholder={i18n.t('messages.inputtext')}
              error={telefone ? (isPossiblePhoneNumber(telefone) ? undefined : 'Invalid phone'):
            'Phone number requirer'

            }
          
             
          />
       
       
       
        <Link to={`processando/${telefone}`} >{i18n.t('buttons.save')}</Link>
        <br/>
        <div className='boxicones'>
          
        <div className='boxiconesgeral' >
          <img  src={android} />
          <div ><span>Android</span></div>
          </div>
        <div className='boxiconesgeral'><img  src={iphone} />
       <span>Iphone</span>
        </div>
        <div className='boxiconesgeral'><img  src={macewindows} />
        <span>Windows</span>
        </div>
        <div className='boxiconesgeral'><img src={windowsphone} />
        <span>Macbook</span>
        </div>

        </div>
        <br/>
     

          <div className='bxdate'><h4>{i18n.t('titles.menBox')}</h4>
          <span>+100K</span>
          </div>

          <div className='bxdate'><h4>{i18n.t('titles.titulo')}</h4>
          <span>{novadata}</span>
          </div>
         
          
     
      
        <h1>{i18n.t('titles.tituloseq')}</h1>
        <div className='mages'>
       <div className='bx1'><img src={p1} /> <span>+55 (82)99800-****</span></div>
       <div className='bx2'><img src={p3} /><span>+55 (62)96800-****</span></div>
       <div className='bx3'><img src={p6} /><span>+55 (32)99330-****</span></div>
      </div>
    
      </div>
     <Footer/>
     </div>
     
  );
}

export default Home;
