import './heade.css'
import {i18n} from '../../Translate/i18n'
import { FaArrowLeft,FaRedo,FaEllipsisV } from "react-icons/fa";
import Logo from '../asserts/logo.png';



function header() {
    return(
        <header>
       <img src={Logo}  width={200}/>
        </header>
    )
}

export default header;