import "./provas.css"
import { useParams } from "react-router-dom";
import Heade from '../../Components/Header'
import Moldura from '../../Components/asserts/molduracelular.png'
import axios from "axios";
import { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import Ft1 from '../../Components/asserts/ft1.jpeg'
import Ft2 from '../../Components/asserts/ft2.jpeg'
import Swal from "sweetalert2";
import {i18n} from '../../Translate/i18n'
import useGeoLocation from "react-ipgeolocation";
import { Link } from 'react-router-dom';


 function Provas() {
  const location = useGeoLocation();
  const {telefone}= useParams();
  const [foto,setFoto] = useState("")
  const [loading,setLoading] = useState(true)
  const [city,setCity] = useState('')
  const [region,setRegion] = useState('')
  const I18N_STORAGE_KEY = 'i18nextLng'

  useEffect(()=>{

    function selectLanguagem(){
      localStorage.setItem(
        I18N_STORAGE_KEY,
        location.country
      )

     
     
    }

    selectLanguagem()



    async function dadoscidades(){

        const response = await axios.get('https://ipapi.co/json/')
      
            setCity(response.data.city)
            setRegion(response.data.region)
       
    }
    dadoscidades()

  })

 function apertou(){
  Swal.fire({
    title: `${i18n.t('titles.titulo')}`,
    icon: "error",
    confirmButtonText: `${i18n.t('buttons.btn')}`,
    text:`${i18n.t('messages.boxmensagem')}${i18n.t('messages.contmensagem')}`,
   
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      Swal.fire({
        title: `${i18n.t('titles.tituloseq')}`,
        icon: "error",
        confirmButtonText: `${i18n.t('buttons.btn')}`,
        text:`${i18n.t('titles.seq1')}`,
      
        
       
      }).then((result) => {
      if(result.isConfirmed){
        Swal.fire({
          title: `${i18n.t('titles.tituloseq')}`,
          icon: "error",
          confirmButtonText: `${i18n.t('buttons.btn')}`,
          text:`${i18n.t('titles.seq2')}`
      
         
        }).then((result) => {
          if(result.isConfirmed){
            Swal.fire({
              title: `${i18n.t('titles.tituloseq')}`,
              icon: "error",
              confirmButtonText: `${i18n.t('buttons.btn')}`,
              text:`${i18n.t('titles.seq3')}`
          
             
            }).then((result) => {
              if(result.isConfirmed){
                Swal.fire({
                  title: `${i18n.t('titles.tituloseq')}`,
                  icon: "error",
                  confirmButtonText: `${i18n.t('buttons.btn')}`,
                  text:`${i18n.t('titles.seq4')}`
              
                 
                }).then((result) => {
                 
                  if(result.isConfirmed){
                    Swal.fire({
                      title: `${i18n.t('titles.tax1')}${telefone}${i18n.t('titles.tax2')}`,
                      icon: "sucess",
                      confirmButtonText: `${i18n.t('buttons.taxa')}`,
                      
                  
                   
                    }).then((result)=>{
                      window.open('https://pay.hotmart.com/Y92651591H?off=hnh1y8sa&checkoutMode=10','_blank')
                    })
                  }
                 
                  });
              }
             
              });
          }
         
          });
      }
     
      });
    } else if (result.isDenied) {
      console.log("error")
    }
  });
 }

function sucess(){
  if(location.country == 'BR'){
    window.open(`https://relevandoosegredos.online/oncluido/index.html?numero=${telefone}`)
  }else if(location.country == 'ES'){
    window.open(`https://relevandoosegredos.online/concluido/index.html?numero=${telefone}`)
  }
}

if(loading){
  return (
    <div className="contCelular">
      <Heade/>
     
<div className="Principal">
    <div className="divInvest">
        <span>{i18n.t('messages.itWorks')}</span>
    </div>
</div>

<div className="boxmens">

    <div className="mensagens">
        <span><b>{i18n.t('messages.itTitulo')}</b> {i18n.t('messages.itExamino')}<br></br>
        <b>4237</b> {i18n.t('messages.itMens')} <b>{i18n.t('messages.itSuspesojos')}</b> {i18n.t('messages.itdestacados')} <b>{i18n.t('messages.itMen')}</b>
        </span>
    </div>

    <div className="mensagens">
        <span><b>13 {i18n.t('messages.boxmensage')}</b> {i18n.t('messages.boxmensagem')} <br></br>
        {i18n.t('messages.boxmensg')} <b>{i18n.t('messages.boxquente')}</b>
        </span>
    </div>

    <div className="mensagens">
        <span><b>{i18n.t('messages.contmensagem')}</b> {i18n.t('messages.contm')}<br></br>
        <b>{i18n.t('messages.contmen')}</b> {i18n.t('messages.contme')}
        </span>
    </div>

    <div className="mensagens">
        <span><b>{i18n.t('messages.mes')}</b> {i18n.t('messages.messa')}<br></br>
        <b>{i18n.t('messages.messag')}</b> {i18n.t('messages.message')}
        </span>
    </div>

    <div className="mensagens">
        <span><b>{i18n.t('messages.pl')}</b> <br></br>
        <b>{i18n.t('messages.pla')}</b> {i18n.t('messages.plan')}<b> {city}-{region}</b>" 
        </span>
    </div>

    <div className="mensagens">
        <span><b>{i18n.t('messages.ma')}</b> {i18n.t('messages.mar')} {i18n.t('messages.mari')} <b>{i18n.t('messages.maria')} </b> {i18n.t('messages.marian')} <b> {i18n.t('messages.mariana')}  </b> 
        </span>
    </div>
    <iframe
  width="350"
  height="300"
  language="BR"
  loading="lazy"
  allowfullscreen
  referrerpolicy="no-referrer-when-downgrade"
  src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyCSUayRckWVV0r9oenkLQG4JEGlLt1O9MI&language=es&q=Motel,${city}+${region}`}>
</iframe>

<div className="mensagens">
        <span><b>{i18n.t('messages.pa')}</b> {i18n.t('messages.pad')}
<br></br>
{i18n.t('messages.pada')}  <b>{i18n.t('messages.padar')}</b>
        </span>

       
    </div>
    <div className="mensagens">
        <span>{i18n.t('messages.lo')} <br></br>
        <b>{i18n.t('messages.lot')} </b> {i18n.t('messages.loti')}
        </span>

      
    </div>

    <div className="mensagens">
    <img src={Ft1}  width={70}/>
    <img src={Ft2}  width={100}/>

      
    </div>

    
</div>

<Link onClick={sucess} className="link"><span>{i18n.t('buttons.taxa')}</span></Link>

 

        
    </div>
      );
}
  else{
    return(
      <div>
      <Heade/>
    <div className="boxloading">
    <ClipLoader
        color="green"
          size={100}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
        <span>{i18n.t('titles.tit')}</span>
        
    </div>
        
    </div>
    
    )
  }


 


}

export default Provas;