import "./celular.css"
import { useParams } from "react-router-dom";
import Heade from '../../Components/Header'
import Moldura from '../../Components/asserts/molduracelular.png'
import axios from "axios";
import { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import whats from '../../Components/asserts/whats.webp'
import instagram from '../../Components/asserts/instagram.png'
import celular from '../../Components/asserts/telefone.png'
import seachbar from '../../Components/asserts/seachbar.png'
import Swal from "sweetalert2";
import {i18n} from '../../Translate/i18n'
import useGeoLocation from "react-ipgeolocation";

 function Celular() {
  const location = useGeoLocation();
  const {telefone}= useParams();
  const [foto,setFoto] = useState("")
  const [loading,setLoading] = useState(false)

  useEffect(()=>{
    async function foto(){
      try {
 
        const response = await axios.get('https://api.z-api.io/instances/3CA35A065383C0C85FE462C45B396465/token/277578B876E7F3F82C4CB01C/profile-picture',{ params: {
        phone:`${telefone}`
      } ,
          headers: {
            'Client-Token' : 'F6416f34c98e9411c8e5d77087d5d1b2cS'
        } })

        console.log(response.data.link)
      
      setFoto(response.data.link)
      setLoading(true)

        
      } catch (error) {
        console.error(error);
        
      }
    }

    
    foto()
  })

 function apertou(){
  Swal.fire({
    title: `${i18n.t('titles.titulo')}`,
    icon: "error",
    confirmButtonText: `${i18n.t('buttons.btn')}`,
    text:`${i18n.t('messages.boxmensagem')}${i18n.t('messages.contmensagem')}`,
   
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      Swal.fire({
        title: `${i18n.t('titles.tituloseq')}`,
        icon: "error",
        confirmButtonText: `${i18n.t('buttons.btn')}`,
        text:`${i18n.t('titles.seq1')}`,
      
        
       
      }).then((result) => {
      if(result.isConfirmed){
        Swal.fire({
          title: `${i18n.t('titles.tituloseq')}`,
          icon: "error",
          confirmButtonText: `${i18n.t('buttons.btn')}`,
          text:`${i18n.t('titles.seq2')}`
      
         
        }).then((result) => {
          if(result.isConfirmed){
            Swal.fire({
              title: `${i18n.t('titles.tituloseq')}`,
              icon: "error",
              confirmButtonText: `${i18n.t('buttons.btn')}`,
              text:`${i18n.t('titles.seq3')}`
          
             
            }).then((result) => {
              if(result.isConfirmed){
                Swal.fire({
                  title: `${i18n.t('titles.tituloseq')}`,
                  icon: "error",
                  confirmButtonText: `${i18n.t('buttons.btn')}`,
                  text:`${i18n.t('titles.seq4')}`
              
                 
                }).then((result) => {
                 
                  if(result.isConfirmed){
                    Swal.fire({
                      title: `${i18n.t('titles.tax1')}${telefone}${i18n.t('titles.tax2')}`,
                      icon: "sucess",
                      confirmButtonText: `${i18n.t('buttons.taxa')}`,
                      
                  
                   
                    }).then((result)=>{
                      window.open('https://pay.hotmart.com/Y92651591H?off=hnh1y8sa&checkoutMode=10','_blank')
                    })
                  }
                 
                  });
              }
             
              });
          }
         
          });
      }
     
      });
    } else if (result.isDenied) {
      console.log("error")
    }
  });
 }



if(loading){
  return (
    <div className="contCelular">
      <Heade/>
     

   <div className="boximagem">
   
      
      <div id="foto" className="fotologo"><img src={foto}/></div>
    
    
      
      <div className="fotomoldura"><img src={Moldura}/></div>
    
      </div>
      <img className="seachbar" src={seachbar} width={300}/>
      <div id="icons" className="boxicons">
        
      <a onClick={apertou} className="fundo">{i18n.t('buttons.btnclicou')}</a>
      
     <img src={whats} />
      <img src={instagram} />
      <img src={celular} />
      </div>
      
 
   
        
    </div>
      );
}
  else{
    return(
      <div>
      <Heade/>
    <div className="boxloading">
    <ClipLoader
        color="green"
          size={100}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
        <span>{i18n.t('titles.tit')}</span>
        
    </div>
        
    </div>
    
    )
  }


 


}

export default Celular;